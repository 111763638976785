<template>
  <div class="container">
    <h1>Добро пожаловать в информационную базу по трудоустройству выпускников ГБПОУ «НАМТ» с мониторингом занятости</h1>
  </div>
</template>

<script>

export default {
  name: 'Home',
  mounted() {
    document.title = "Главная"
  }
}
</script>

<style>

</style>
