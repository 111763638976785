<template>
  <div class="container p-0">
    <div class="row">
      <h1 class="text-center" style="margin-bottom: 24px;">Архив выпускника</h1>
      <div class="card p-0 offset-2 col-8">
        <h3 class="card-header text-center">{{student_employment.last_name}} {{student_employment.first_name}} {{student_employment.second_name}}</h3>
        <div class="card-body d-flex flex-column align-items-center">
          <table class="table-striped table table-hover my-0">
            <thead>
              <tr>
                <th style="user-select: none;">Вид деятельности</th>
                <th style="user-select: none;">Месяц начала деятельности</th>
                <th style="user-select: none;">Организация</th>
                <th style="user-select: none;">Специальность/профессия</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="employment in student_employment.employments">
                <td>{{ employment.activity }}</td>
                <td style="width: 150px">{{ employment.month }}</td>
                <td>{{ employment.organization }}</td>
                <td v-if="employment.is_specialization === 1"><span class="badge bg-success">{{ employment.profession }}</span></td>
                <td v-else class=""><span class="badge bg-danger">{{ employment.profession }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import select2Formatier from "../features/select2-formatier";


export default {
  name: "StudentArchive",
  data: ()=>({
    student_employment : [],
    organization : "",
    profession : "",
    is_specialization : false,
    //
    activities : [],
    activity : 0,
    //
    months : ["Январь" , "Февраль" , "Март" , "Апрель" , "Май" , "Июнь" , "Июль" , "Август" , "Сентябрь" , "Октябрь" , "Ноябрь" , "Декабрь"],
    month : "",
    componentKey : 0
  }),
  methods : {
    getActivities() {
      this.$Loading.start()
      axios.get("https://api.r4guda.xyz/api/activity")
        .then(response => {
          this.activities = select2Formatier(response.data.data)
          this.$Loading.finish()
        })
    },
    getStudentEmployment() {
      this.$Loading.start()
      axios.get("https://api.r4guda.xyz/api/student/" + this.$route.params.id)
      .then(response => this.student_employment = response.data.data)
      .catch(error => this.somethingGoWrong())
      .finally(()=>this.$Loading.finish())
    },
    storeEmployment() {
      this.$Loading.start()
      axios.post("https://api.r4guda.xyz/api/employment", {
        student_id : this.$route.params.id,
        organization : this.organization,
        activity_id : this.activity,
        profession : this.profession,
        is_specialization : this.is_specialization,
        month : this.month
      })
      .then(response => {
        this.student_employment.employments.push(response.data.data)
        this.success()
      })
      .catch(error => this.somethingGoWrong())
      .finally(()=>this.$Loading.finish())
    },
    deleteStudentEmployment(id) {
      axios.delete('https://api.r4guda.xyz/api/employment/' + id)
      .then(() => {
        this.getStudentEmployment()
      })
    },
    myEventHandler(e) {
      this.componentKey++
    },
    somethingGoWrong() {
      this.$Message.danger({ text: 'Что-то пошло не так...' })
    },
    success() {
      this.$Message.success({ text: 'Успешно' })
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getActivities()
    this.getStudentEmployment()
    document.title = "Архивная запись о студенте"
  }
}
</script>

<style>
</style>
