import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Select2 from 'vue3-select2-component';
import beforeGuard from "./router/beforeGuard";
import axios from "axios";
import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'

window.$ = window.jQuery = require('jquery');

// Handle errors for all request
axios.interceptors.response.use(response => response, error => {
    // Authorization error (Api token incorrect)
    if (error.response?.status === 401) {
        localStorage.removeItem('api_token')
        router.push('Login')
    }
    return Promise.reject(error)
})

router.beforeEach(beforeGuard)

createApp(App).component('Select2', Select2).use(Equal).use(store).use(router).mount('#app')
