<template>
  <div class="container p-0 d-flex flex-column justify-content-between h-100">
    <div class="row">
      <h1>Архив</h1>
      <div class="row">
        <div class="card">
          <div class="row p-2 align-items-end">
            <div class="col-2">
              <div class="select-wrapper">
                <p style="user-select: none;" class="mb-0">Группа</p>
                <div style="position: relative" class="select__inner">
                  <Select2 :key="componentKey" class="mb-1 w-100" v-model="group" :options="groups" />
                  <button @click="this.group = 0" style="position: absolute; padding-top: 0; right: 15px; top: 1px; color: #444444; background: white; border: 0">x</button>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="select-wrapper">
                <p style="user-select: none;" class="mb-0">Специальность</p>
                <div style="position: relative" class="select__inner">
                  <Select2 :key="componentKey" class="mb-1 w-100" v-model="specialization" :options="specializations" />
                  <button @click="this.specialization = 0" style="position: absolute; padding-top: 0; right: 15px; top: 1px; color: #444444; background: white; border: 0">x</button>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="select-wrapper">
                <p style="user-select: none;" class="mb-0">Преподаватель</p>
                <div class="select__inner position-relative">
                  <Select2 :key="componentKey" class="mb-1 w-100" v-model="user" :options="users" />
                  <button @click="this.user = 0" style="position: absolute; padding-top: 0; right: 15px; top: 1px; color: #444444; background: white; border: 0">x</button>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="select-wrapper">
                <p style="user-select: none;" class="mb-0">Поиск</p>
                <input v-model="text" class="text-input mb-1 w-100" placeholder="ФИО..." type="text">
              </div>
            </div>
            <div class="col-3 row">
              <div class="p-1 col-9">
                <button @click="changeType('search'); searchStudent();" class="btn btn-lg btn-primary w-100 p-1">Поиск</button>
              </div>
              <div class="p-1 col-3">
                <button @click="changeType('default');" style="padding-bottom: 10px;" class="btn btn-lg btn-danger w-100 p-1"><img style="width: 40%; margin-bottom: 2px;" src="@/assets/vectorpaint(2).svg" alt=""></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex">
        <div v-for="item in students_employment" class="p-2 col-xl-4 col-lg-6">
          <details class="card">
            <summary style="user-select: none; " class="p-2">{{item.last_name + " " + item.first_name + " " + item.second_name + " " + item.group}}</summary>
            <div class="row ps-3 pe-3">
              <table class="table-striped table table-hover my-0">
                <thead>
                <tr>
                  <th style="user-select: none; " class="">Организация</th>
                  <th style="user-select: none; " class="">Специальность</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="employment in item.employments">
                  <td>{{ employment.organization }}</td>
                  <td v-if="employment.is_specialization === 1"><span class="badge bg-success">{{ employment.profession }}</span></td>
                  <td v-else class=""><span class="badge bg-danger">{{ employment.profession }}</span></td>
                </tr>
                </tbody>
              </table>
              <router-link target="_blank" class="mt-1 mb-1 btn btn-lg btn-primary p-0" :to="{name : 'StudentArchive', params: {id : item.id}}" type="button">Подробнее</router-link>
            </div>
          </details>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button @click="nextStudent" class="btn btn-secondary">Больше</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import select2Formatier from "../features/select2-formatier";

export default {
  name: "Archive",
  data: ()=>({
    students_employment: [],
    group: 0,
    specialization: 0,
    user: 0,
    groups: [],
    specializations: [],
    users: [],
    text: "",
    offset: 0,
    type: 'default',
    componentKey: 0,
  }),
  methods: {
    getStudentEmployment() {
      axios.get('https://api.r4guda.xyz/api/employment',{
        params : {
          "offset" : this.offset,
          "archived" : 1
        }
      })
      .then(response => response.data.data.forEach(el => this.students_employment.push(el)))
    },
    changeType(type) {
      this.offset = 0
      this.type = type
      this.students_employment = []
      if (this.type === 'default') {
        this.text = ""
        this.group = 0
        this.specialization = 0
        this.user = 0
        this.getStudentEmployment()
      }
    },
    nextStudent() {
      this.offset++
      if (this.type === "default")
        this.getStudentEmployment()
      else
        this.searchStudent()
    },
    //
    getUsers() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/user')
      .then(response=>this.users = select2Formatier(response.data.data))
      .finally(()=>this.$Loading.finish())
    },
    getGroups() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/group',{
        params : {
          "archived" : 1
        }
      })
      .then(response=>this.groups = select2Formatier(response.data.data))
      .finally(()=>this.$Loading.finish())
    },
    getSpecializations() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/specialization')
      .then(response=>this.specializations = select2Formatier(response.data.data))
      .finally(()=>this.$Loading.finish())
    },
    searchStudent() {
      this.$Loading.start()
      axios.get("https://api.r4guda.xyz/api/search/employments",{
        params: {
          "group" : this.group,
          "user" : this.user,
          "specialization" : this.specialization,
          "text" : this.text,
          "offset" : this.offset,
          "archived" : 1
        }
      })
      .then(response => response.data.data.forEach(el => this.students_employment.push(el)))
      .finally(()=>this.$Loading.finish())
    },
    myEventHandler(e) {
      this.componentKey++
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getStudentEmployment()
    this.getUsers()
    this.getGroups()
    this.getSpecializations()
    document.title = "Архив"
    //
  }
}
</script>

<style scoped>
DETAILS[open] SUMMARY ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
