import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import Students from "../views/Students";
import Another from "../views/Another";
import Employment from "../views/Employment";
import Report from "../views/Report";
import StudentEmployment from "../views/StudentEmployment";
import Auth from "../layouts/Auth";
import Login from "../views/Login";
import auth from "./middleware/auth";
import Default from "../layouts/Default";
import Archive from "../views/Archive";
import StudentArchive from "../views/StudentArchive";
import admin from "./middleware/admin";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta : {
      layout : Default,
      middleware : [auth]
    }
  },
  {
    path: '/students',
    name: 'students',
    component: Students,
    meta : {
      layout : Default,
      middleware : [admin]
    }
  },
  {
    path: '/another',
    name: 'another',
    component: Another,
    meta : {
      layout : Default,
      middleware : [admin]
    }
  },
  {
    path: '/employment',
    name: 'Employment',
    component: Employment,
    meta : {
      layout : Default,
      middleware : [auth]
    }
  },
  {
    path: '/employment/:id',
    name: 'StudentEmployment',
    component: StudentEmployment,
    meta : {
      layout : Default,
      middleware : [auth]
    }
  },
  {
    path: '/archive/:id',
    name: 'StudentArchive',
    component: StudentArchive,
    meta : {
      layout : Default,
      middleware : [admin]
    }
  },
  {
    path: '/archive',
    name: 'Archive',
    component: Archive,
    meta : {
      layout : Default,
      middleware : [admin]
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta : {
      layout : Default,
      middleware : [admin]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta : {
      layout : Auth
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
