<template>
  <div class="container p-0">
    <div class="row">
      <h1 class="text-center" style="margin-bottom: 24px;">Трудоустройство выпускника</h1>
      <div class="card p-0 offset-2 col-8">
        <h3 class="card-header text-center">{{student_employment.last_name}} {{student_employment.first_name}} {{student_employment.second_name}}</h3>
        <div class="card-body d-flex flex-column align-items-center">
          <form v-if="isAdmin" class="mb-1 d-flex flex-column align-items-center w-50">
            <div class="select-wrapper w-100">
              <p style="user-select: none;" class="mb-0">Вид деятельности</p>
              <Select2 :key="componentKey" class="mb-1 w-100" v-model="activity" :options="activities" />
            </div>
            <div class="select-wrapper w-100">
              <p style="user-select: none;" class="mb-0">Месяц начала деятельности</p>
              <Select2 :key="componentKey" class="mb-1 w-100" v-model="month" :options="months" />
            </div>
            <input v-model="organization" placeholder="Организация" class="mb-1 w-100 text-input"  type="text">
            <input v-model="profession" placeholder="Специальность" class="mb-1 w-100 text-input" type="text">
            <div class="form-check">
              <label style="user-select: none;" class="form-check-label">
                Деятельность по специальности
              </label>
              <input v-model="is_specialization" class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate">
            </div>
            <input @click="storeEmployment" class="btn btn-lg btn-info w-100" value="Добавить" type="button">
          </form>
          <table class="table-striped table table-hover my-0">
            <thead>
              <tr>
                <th style="user-select: none;">Вид деятельности</th>
                <th style="user-select: none;">Месяц начала деятельности</th>
                <th style="user-select: none;">Организация</th>
                <th style="user-select: none;">Специальность/профессия</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="forceReload" v-for="employment in student_employment.employments">
                <td>{{ employment.activity }}</td>
                <td style="width: 150px">{{ employment.month }}</td>
                <td>{{ employment.organization }}</td>
                <td><span :class="{'bg-success' : employment.is_specialization, 'bg-danger' : !employment.is_specialization}" class="badge">{{ employment.profession }}</span></td>
                <td v-if="isAdmin" class="d-none d-md-table-cell"><button @click="deleteStudentEmployment(employment.id)" class="border-0" style="width: 30px; height: 30px; background: none;"><img class="w-100" src="@/assets/premium-icon-close-4013407.png" alt=""></button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import select2Formatier from "../features/select2-formatier";


export default {
  name: "StudentEmployment",
  data: ()=>({
    student_employment : [],
    organization : "",
    profession : "",
    is_specialization : false,
    //
    activities : [],
    activity : 0,
    //
    months : ["Январь" , "Февраль" , "Март" , "Апрель" , "Май" , "Июнь" , "Июль" , "Август" , "Сентябрь" , "Октябрь" , "Ноябрь" , "Декабрь"],
    month : "",
    componentKey : 0,
    forceReload : 0
  }),
  computed : {
    isAdmin() {
      return this.$store.getters.isAdmin
    }
  },
  methods : {
    getActivities() {
      this.$Loading.start()
      axios.get("https://api.r4guda.xyz/api/activity")
        .then(response => {
          this.activities = select2Formatier(response.data.data)
          this.$Loading.finish()
        })
    },
    getStudentEmployment() {
      this.$Loading.start()
      axios.get("https://api.r4guda.xyz/api/student/" + this.$route.params.id)
      .then(response => this.student_employment = response.data.data)
      .catch(error => this.somethingGoWrong())
      .finally(()=>{
        this.$Loading.finish()
        this.forceReload++
      })
    },
    storeEmployment() {
      this.$Loading.start()
      axios.post("https://api.r4guda.xyz/api/employment", {
        student_id : this.$route.params.id,
        organization : this.organization,
        activity_id : this.activity,
        profession : this.profession,
        is_specialization : this.is_specialization,
        month : this.month
      })
      .then(response => {
        this.student_employment.employments.push(response.data.data)
        this.success()
      })
      .catch(error => this.somethingGoWrong())
      .finally(()=>this.$Loading.finish())
    },
    deleteStudentEmployment(id) {
      axios.delete('https://api.r4guda.xyz/api/employment/' + id)
      .then(() => {
        this.getStudentEmployment()
      })
    },
    myEventHandler(e) {
      this.componentKey++
    },
    somethingGoWrong() {
      this.$Message.danger({ text: 'Что-то пошло не так...' })
    },
    success() {
      this.$Message.success({ text: 'Успешно' })
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getActivities()
    this.getStudentEmployment()
    document.title = "Трудоустройство выпускника"
  }
}
</script>

<style>
</style>
