<template>
  <div style="height: 100vh;" class="auth-wrapper  d-flex flex-column align-items-center justify-content-center">
    <div class="position-absolute">
      <form @submit.prevent style="border-radius: 0.25rem; width: 400px;" class="admin bg-light text-white p-3">
        <input class="w-100" v-model="login" placeholder="Логин" type="text">
        <input v-model="password" class="mt-1 mb-2 w-100" placeholder="Пароль" type="password">
        <input @click="auth" type="submit" class="col-12 btn btn-outline-dark">
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";

export default {
  name: "Login",
  data: () => ({
    login : "",
    password : "",
    error : ""
  }),
  methods: {
    auth() {
      axios.post('https://api.r4guda.xyz/api/login', {
        login : this.login,
        password : this.password
      })
      .then(response => {
        localStorage.setItem('id',response.data.data.id)
        localStorage.setItem('api_token',response.data.data.api_token)
        localStorage.setItem('is_admin', response.data.data.is_admin)
        axios.defaults.headers.common['Authorization'] =  `Bearer ${response.data.data.api_token}`
        router.push('/')
      })
      .catch(error => this.$Message.danger({ text: 'Проверьте корректность введенных данных' }))

    }
  },
  mounted() {
    document.title = "Вход"
    localStorage.removeItem("api_token")
    localStorage.removeItem("is_admin")
    localStorage.removeItem("id")
  }
}
</script>

<style scoped>
</style>
