<template>

  <div class="wrapper">
    <nav id="sidebar" class="sidebar js-sidebar">
      <div class="sidebar-content js-simplebar position-fixed ">
        <router-link class="sidebar-brand" to="/">
          <span class="align-middle">послеНАМТа</span>
        </router-link>

        <ul class="sidebar-nav">
          <li class="sidebar-item">
            <router-link class="sidebar-link" to="/employment">
              <span class="align-middle">Трудоустройство</span>
            </router-link>
          </li>
          <li v-if="isAdmin" class="sidebar-item">
            <router-link class="sidebar-link" to="/students">
              <span class="align-middle">Список выпускников</span>
            </router-link>
          </li>
          <li v-if="isAdmin" class="sidebar-item">
            <router-link class="sidebar-link" to="/another">
              <span class="align-middle">Прочее</span>
            </router-link>
          </li>
          <li v-if="isAdmin" class="sidebar-item">
            <router-link class="sidebar-link" to="/archive">
              <span class="align-middle">Архив</span>
            </router-link>
          </li>
          <li v-if="isAdmin" class="sidebar-item">
            <a class="sidebar-link" href="https://api.r4guda.xyz/api/report">
              <span class="align-middle">Отчет <img style="max-width: 15px; color: white;" src="@/assets/vectorpaint.svg" alt=" скачать"></span>
            </a>
          </li>
        </ul>

        <button class="sidebar-link border-0 text-start" @click="logout">Выйти</button>
      </div>
    </nav>
    <div class="main">
      <main class="content">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>


export default {
  name: "Default",
  computed : {
    isAuthorized () {
      return this.$store.getters.isAuthorized
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout")
      this.$router.push("login")
    },
  }
}
</script>

<style scoped>
@import "../assets/css/app.css";
</style>
