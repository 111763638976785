<template>
  <div class="container p-0 d-flex flex-column h-100 justify-content-between">
    <div class="row">
      <h1>Студенты</h1>
      <div class="row p-0 m-0">
        <div class="card col-7 p-2">
          <div class="row flex-row">
            <div class="col-5 d-flex flex-column gap-1">
              <input v-model="last_name" class="text-input" placeholder="Фамилия" type="text">
              <input v-model="first_name" class="text-input" placeholder="Имя" type="text">
              <input v-model="second_name" class="text-input" placeholder="Отчество" type="text">
              <InputMask mask="9 (999) 999-99-99" v-model="phone" class="phone-number text-input" placeholder="Номер телефона" type="text"/>
              <InputMask mask="9 (999) 999-99-99" v-model="parents_phone" class="phone-number text-input" placeholder="Номер телефона родителей" type="text"/>
            </div>
            <div class="col-7 d-flex flex-column gap-1">
              <div class="select-wrapper d-flex flex-row align-items-center">
                <span class="me-2">Группа: </span>
                <div class="select2-wrapper w-100">
                  <Select2 :key="componentKey" v-model="group" :options="groups"/>
                </div>
              </div>
              <div class="select-wrapper d-flex flex-row align-items-center">
                <span class="me-2">Специальность: </span>
                <div class="select2-wrapper w-100">
                  <Select2 :key="componentKey" v-model="specialization" :options="specializations"/>
                </div>
              </div>
              <div class="select-wrapper d-flex flex-row mb-1 align-items-center">
                <span class="me-2 w-75">Классный руководитель: </span>
                <div class="select2-wrapper w-100">
                  <Select2 :key="componentKey" v-model="user" :options="users"/>
                </div>
              </div>
              <div class="col-12 ">
                <input @click="storeStudent" type="button" value="Создать" style="height: 55px" class="btn btn-lg btn-info w-100">
              </div>
            </div>
          </div>
        </div>

        <div class="card offset-1 col-4">
          <div class="w-100 p-1">
            <div class="select-wrapper d-flex flex-row align-items-center mb-1">
              <span class="me-2">Группа: </span>
              <div class="select2-wrapper w-100 position-relative">
                <Select2 :key="componentKey" v-model="search_group" :options="groups"/>
                <button @click="this.search_group = 0" style="position: absolute; padding-top: 0; right: 15px; top: 1px; color: #444444; background: white; border: 0">x</button>
              </div>
            </div>
            <div class="select-wrapper d-flex flex-row mb-1 align-items-center">
              <span class="me-2">Специальность: </span>
              <div class="select2-wrapper w-100 position-relative">
                <Select2 :key="componentKey" v-model="search_specialization" :options="specializations"/>
                <button @click="this.search_specialization = 0" style="position: absolute; padding-top: 0; right: 15px; top: 1px; color: #444444; background: white; border: 0">x</button>
              </div>
            </div>
            <div class="select-wrapper d-flex flex-row mb-1 align-items-center">
              <span class="me-2 w-75">Классный руководитель: </span>
              <div class="select2-wrapper w-75 position-relative">
                <Select2 :key="componentKey" v-model="search_user" :options="users"/>
                <button @click="this.search_user = 0" style="position: absolute; padding-top: 0; right: 15px; top: 1px; color: #444444; background: white; border: 0">x</button>
              </div>
            </div>
            <input v-model="text" class="text-input mb-1 w-100" placeholder="Поиск" type="text">
            <div class="row ps-2 pe-2">
              <div class="p-1 col-9">
                <button @click="changeType('search'); searchStudent();" class="btn btn-lg btn-primary w-100 p-1">Поиск</button>
              </div>
              <div class="p-1 col-3">
                <button @click="changeType('default');" style="height: 32px" class="btn btn-lg btn-danger w-100 p-1"><img style="width: 30%; margin-bottom: 4px;" src="@/assets/vectorpaint(2).svg" alt=""></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card col-12">
        <table class="table table-striped table-hover my-0">
          <thead>
          <tr>
            <th style="user-select: none;">ФИО</th>
            <th style="user-select: none;" class="d-none d-xl-table-cell">Группа</th>
            <th style="user-select: none;" class="d-none d-md-table-cell">Специальность</th>
            <th style="user-select: none;">Классный руководитель</th>
            <th style="user-select: none;" class="d-none d-md-table-cell">Контактный телефон</th>
            <th style="user-select: none;" class="d-none d-md-table-cell">Контактный телефон родителей</th>
            <th style="user-select: none;" class="d-none d-md-table-cell"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in students">
            <td class="d-none d-xl-table-cell">{{ item.last_name + " " + item.first_name + " " + item.second_name}}</td>
            <td style="width: 100px;" class="d-none d-md-table-cell">{{ item.group}}</td>
            <td class="d-none d-md-table-cell">{{ item.specialization }}</td>
            <td>{{ item.user_last_name + " " + item.user_first_name[0] + ". " + item.user_second_name[0] + "." }}</td>
            <td class="d-md-table-cell ">
              <div class="cell-wrapper d-flex">
                <InputMask mask="9 (999) 999-99-99"  class="phone-number text-input" style="width: 90%;border-radius: 4px 0 0 4px " type="text" v-model="item.phone"/>
                <button @click="updateNumber(item.id, item.phone, 'phone')" style="height: 28px; border-radius: 0 4px 4px 0; border: 1px solid #aaa; border-left: 0"><i class="pi pi-check"></i></button>
              </div>
            </td>
            <td class="d-none d-md-table-cell">
              <div class="cell-wrapper d-flex">
                <InputMask mask="9 (999) 999-99-99"  class="phone-number text-input" style="width: 90%;border-radius: 4px 0 0 4px " type="text" v-model="item.parents_phone"/>
                <button @click="updateNumber(item.id, item.parents_phone, 'phone')" style="height: 28px; border-radius: 0 4px 4px 0; border: 1px solid #aaa; border-left: 0"><i class="pi pi-check"></i></button>
              </div>
            </td>
            <td class="d-none d-md-table-cell"><button @click="deleteStudent(item.id)" class="border-0" style="width: 30px; height: 30px; background: none;"><img class="w-100" src="@/assets/premium-icon-close-4013407.png" alt=""></button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button @click="nextStudent" class="btn btn-secondary">Больше</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import select2Formatier from "../features/select2-formatier";
import InputMask from 'primevue/inputmask'

export default {
  name: "Students",
  components : {
    select2Formatier,
    InputMask
  },
  data: ()=>({
    students: [],
    groups: [],
    specializations: [],
    users: [],
    //studentInfo
    last_name : "",
    first_name : "",
    second_name : "",
    phone : "",
    parents_phone : "",
    group: 0,
    specialization : 0,
    user : 0,
    //
    search_group: 0,
    search_specialization : 0,
    search_user : 0,
    text : "",
    offset : 0,
    type : "default",
    componentKey : 0
  }),
  methods: {
    updateNumber(id, phone, field) {
      let data = {}
      data[field] = phone
      axios.patch('https://api.r4guda.xyz/api/student/' + id, data)
      .then(response => this.success())
      .catch(error => this.somethingGoWrong())
    },
    deleteStudent(id){
      this.$Loading.start()
      axios.delete('https://api.r4guda.xyz/api/student/' + id)
      .then(()=> {
        this.changeType("default")
        this.success()
      })
      .catch(error => this.somethingGoWrong())
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    getStudents() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/student',{
        params : {
          "offset" : this.offset
        }
      })
      .then(response => response.data.data.forEach(el => this.students.push(el)))
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    changeType(type) {
      this.offset = 0
      this.type = type
      this.students = []
      if (this.type === 'default') {
        this.getStudents()
      }
    },
    nextStudent() {
      this.offset++
      if (this.type === "default")
        this.getStudents()
      else
        this.searchStudent()
    },
    searchStudent(){
      this.$Loading.start()
      axios.get("https://api.r4guda.xyz/api/search/students",{
        params: {
          "group" : this.search_group,
          "user" : this.search_user,
          "specialization" : this.search_specialization,
          "text" : this.text,
          "offset" : this.offset
        }
      })
      .then(response => response.data.data.forEach(el => this.students.push(el)))
      .catch(error => this.somethingGoWrong())
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    getGroups() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/group')
      .then(response => this.groups = select2Formatier(response.data.data))
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    getSpecializations() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/specialization')
      .then(response => this.specializations = select2Formatier(response.data.data))
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    getUsers() {
      this.$Loading.start()
      axios.get('https://api.r4guda.xyz/api/user')
      .then(response => this.users = select2Formatier(response.data.data))
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    //
    storeStudent() {
      this.$Loading.start()
      if (this.last_name === "" || this.first_name === "" || this.second_name === "" || this.phone === "" || this.parents_phone === "" || this.group === 0 || this.user === 0 || this.specialization === 0 ) {
        this.$Message.danger({ text: 'Заполните все поля!' })
        this.$Loading.finish()
        return
      }
      axios.post('https://api.r4guda.xyz/api/student', {
        "last_name" : this.last_name,
        "first_name" : this.first_name,
        "second_name" : this.second_name,
        "phone" : this.phone,
        "parents_phone" : this.parents_phone,
        "group_id" : this.group,
        "specialization_id" : this.specialization,
        "user_id" : this.user,
      })
      .then(response => {
        this.changeType("default")
        this.success()
        //reset input
        this.last_name = ""
        this.first_name = ""
        this.second_name = ""
        this.phone = ""
        this.parents_phone = ""
      })
      .catch(error => this.somethingGoWrong())
      .finally(()=>{
        this.$Loading.finish()
      })
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true;
      }
    },
    myEventHandler(e) {
      this.componentKey++
    },
    somethingGoWrong() {
      this.$Message.danger({ text: 'Что-то пошло не так...' })
    },
    success() {
      this.$Message.success({ text: 'Успешно!' })
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getStudents()
    this.getGroups()
    this.getSpecializations()
    this.getUsers()
    document.title = "Список студентов"
  }
}
</script>

<style scoped>
@import "~primeicons/primeicons.css";
</style>
