<template>
  <slot></slot>
</template>

<script>
import axios from "axios";
import router from "../router";

export default {
  name: "Auth"
}
</script>

<style scoped>

</style>
