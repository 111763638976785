<template>
  <div class="container p-0">
    <div class="row">
      <!--   USERS   -->
      <div class="p-2 col-12 ">
        <h2 class="text-center">Классные руководитель/Пользователь</h2>
        <div class="p-2 card">
          <div class="col-xl-12 row mb-1">
            <div class="col-xl-4 d-flex flex-column gap-1">
              <input class="text-input" v-model="userFirstName" placeholder="Фамилия" type="text">
              <input class="text-input" v-model="userLastName" placeholder="Имя" type="text">
              <input class="text-input" v-model="userSecondName" placeholder="Отчество" type="text">
            </div>
            <div class="col-xl-4 d-flex flex-column gap-1">
              <input class="text-input" v-model="userLogin" placeholder="Логин" type="text">
              <input class="text-input" v-model="userPassword" placeholder="Пароль" type="text">
            </div>
            <div class="col-xl-4 d-flex justify-content-center flex-column gap-1">
              <Select2 :key="componentKey" v-model="user" :options="users"/>
            </div>
          </div>
          <div class="row col-xl-12">
            <div class="col-xl-8">
              <input @click="storeUser" type="button" value="Создать" class="btn btn-lg btn-info w-100">
            </div>
            <div class="col-xl-4">
              <input @click="deleteUsers" type="button" value="Удалить" class="btn btn-lg btn-danger w-100">
            </div>
          </div>
        </div>
      </div>
      <!--   SPEC   -->
      <div class="p-2 col-6">
        <h2>Специальности</h2>
        <div class="p-2 card">
          <div class="col-xl-12 row mb-1">
            <div class="col-xl-6 d-flex flex-column gap-1">
              <input class="text-input" v-model="specializationTitle" placeholder="Название" type="text">
            </div>
            <div class="col-xl-6 d-flex flex-column gap-1">
              <Select2 :key="componentKey" v-model="specialization" :options="specializations"/>
            </div>
          </div>
          <div class="row col-xl-12">
            <div class="col-xl-6">
              <input @click="storeSpecialization" type="button" value="Создать" class="btn btn-lg btn-info w-100">
            </div>
            <div class="col-xl-6">
              <input @click="deleteSpecializations" type="button" value="Удалить" class="btn btn-lg btn-danger w-100">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   GROUPS   -->
    <div class="row">
      <!--   GROUPS MANIPULATE  -->
      <div class="p-2 col-6">
        <h2>Группы</h2>
        <div class="p-2 card">
          <div class="col-xl-12 row mb-1">
            <div class="col-xl-6 d-flex flex-column gap-1">
              <input class="text-input" placeholder="Номер" v-model="groupTitle" type="text">
            </div>
            <div class="col-xl-6">
              <Select2 :key="componentKey" style="width: 100%" v-model="group" :options="groups"/>
            </div>
          </div>
          <div class="row col-xl-12">
            <div class="col-xl-6">
              <input @click="storeGroup" type="button" value="Создать" class="btn btn-lg mb-1 btn-info w-100">
            </div>
            <div class="col-xl-6">
              <div class="row">
                <div class="col-6">
                  <input @click="archiveGroup" type="button" value="В архив" class="btn btn-lg mb-1 btn-warning w-100">
                </div>
                <div class="col-6">
                  <input @click="deleteGroups" type="button" value="Удалить" class="btn btn-lg mb-1 btn-danger w-100">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--   GROUPS LINK USERS  -->
      <div class="p-2 col-6">
        <h2>Дать доступ пользователю к группе</h2>
        <div class="p-2 card">
          <div class="col-xl-12 row mb-1">
            <div class="col-xl-6 d-flex flex-column gap-1">
              <p>Пользователь<Select2 :key="componentKey" v-model="link_user" :options="users"/></p>
              <p>Группа<Select2 :key="componentKey" style="width: 100%" v-model="link_group" :options="groups"/></p>
              <input @click="linkUserGroup" type="button" value="Предоставить доступ" class="btn btn-lg mb-1 btn-info w-100">
            </div>
            <div class="col-xl-6 d-flex flex-column gap-1">
              <p>Пользователь<Select2 :key="componentKey" v-model="unlink_user" :options="users"/></p>
              <p>Группа<Select2 :key="componentKey" style="width: 100%" v-model="unlink_group" :options="linked_groups"/></p>
              <input @click="unlinkUserGroup" type="button" value="Лишить доступа" class="btn btn-lg mb-1 btn-danger w-100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import select2Formatier from "../features/select2-formatier"

export default {
  name: "Another",
  data: ()=>({
    groups: [],
    specializations: [],
    users: [],
    group: 0,
    groupTitle: "",
    specialization: 0,
    specializationTitle: "",
    user: 0,
    userFirstName: "",
    userLastName: "",
    userSecondName: "",
    userLogin: "",
    userPassword: "",
    componentKey: 0,
    //
    link_user: 0,
    link_group: 0,
    //
    linked_groups : [],
    unlink_user: 0,
    unlink_group: 0
  }),
  components: {
    select2Formatier
  },
  methods: {
    //groups
    getGroups() {
      axios.get('https://api.r4guda.xyz/api/group')
      .then(response => this.groups = select2Formatier(response.data.data))
    },
    deleteGroups() {
      axios.delete(`https://api.r4guda.xyz/api/group/${this.group}`)
      .then(response => {
        this.getGroups()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
   archiveGroup() {
      axios.put(`https://api.r4guda.xyz/api/group/${this.group}`, {
        params : {
          'archived' : 1
        }
      })
      .then(response => {
        this.getGroups()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
    storeGroup() {
      axios.post('https://api.r4guda.xyz/api/group', {
        "title" : this.groupTitle
      })
      .then(response => {
        this.getGroups()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },

    //specialization
    getSpecializations() {
      axios.get('https://api.r4guda.xyz/api/specialization')
      .then(response => this.specializations = select2Formatier(response.data.data))
    },
    deleteSpecializations() {
      axios.delete(`https://api.r4guda.xyz/api/specialization/${this.specialization}`)
      .then(response => {
        this.getSpecializations()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
    storeSpecialization() {
      axios.post('https://api.r4guda.xyz/api/specialization', {
        "title" : this.specializationTitle
      })
      .then(response => {
        this.getSpecializations()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
    //user
    getUsers() {
      axios.get('https://api.r4guda.xyz/api/user')
          .then(response => this.users = select2Formatier(response.data.data))
    },
    deleteUsers() {
      axios.delete(`https://api.r4guda.xyz/api/user/${this.user}`)
      .then(response => {
        this.getUsers()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
    storeUser() {
      axios.post('https://api.r4guda.xyz/api/user', {
        "last_name" : this.userFirstName,
        "first_name" : this.userLastName,
        "second_name" : this.userSecondName,
        "login" : this.userLogin,
        "password" : this.userPassword,
      })
      .then(response => {
        this.getUsers()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
    //
    linkUserGroup() {
      axios.post('https://api.r4guda.xyz/api/linkGroupUser', {
        "user_id" : this.link_user,
        "group_id" : this.link_group,
      })
      .then(response => {
        this.getLinkedToUserGroups()
        this.success()
      })
      .catch(error => this.somethingGoWrong())
    },
    unlinkUserGroup() {
      axios.delete('https://api.r4guda.xyz/api/unlinkGroupUser', {
        params : {
          "user_id" : this.unlink_user,
          "group_id" : this.unlink_group,
        }
      })
      .then(response => {
        this.success()
      })
    },
    getLinkedToUserGroups() {
      axios.get("https://api.r4guda.xyz/api/getLinkedGroupUser",{
        params : {
          "user_id" : this.unlink_user
        }
      })
      .then(response => {
        this.linked_groups = select2Formatier(response.data.data)
      })
    },
    //
    myEventHandler(e) {
      this.componentKey++
    },
    somethingGoWrong() {
      this.$Message.danger({ text: 'Что-то пошло не так...' })
    },
    success() {
      this.$Message.success({ text: 'Успешно' })
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  watch: {
    unlink_user() {
      this.getLinkedToUserGroups()
    }
  },
  mounted() {
    this.getGroups();
    this.getSpecializations();
    this.getUsers();
    document.title = "Прочее"
  }
}
</script>

<style scoped>

</style>
