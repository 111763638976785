import axios from "axios";
import router from "../../router";

export default {
    state : {
        user : [],
    },
    getters : {
        isAuthorized(state) {
            return !!localStorage.getItem("api_token")
        },
        apiToken(state) {
            return localStorage.getItem("api_token")
        },
        isAdmin(state) {
            return localStorage.getItem("is_admin") === "1"
        }
    },
    actions : {
        verifyToken({getters}, user) {
            axios.defaults.headers.common['Authorization'] = getters.apiToken ? `Bearer ${getters.apiToken}` : null
            axios.get('https://api.r4guda.xyz/api/verifyToken')
        },
        logout() {
            localStorage.removeItem("api_token")
            localStorage.removeItem("is_admin")
            localStorage.removeItem("id")
        }
    },
    mutations: {
        setUser(state) {

        }
    }
}
