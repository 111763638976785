export default function admin({next, store, nextInPipeline}) {
    console.log(!store.getters.isAuthorized)
    if (!store.getters.isAuthorized) {
        return next({ name: 'Login' })
    }

    if (!store.getters.isAdmin) {
        return next({ name: 'Home' })
    }

    store.dispatch('verifyToken')
    return nextInPipeline()
}
