<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<style>

* {
  outline: none;
}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

.text-input {
  padding-left: 8px;
  padding-right: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #aaa;
  height: 28px;
}
</style>

<script>
import Default from "./layouts/Default";
import Auth from "./layouts/Auth";

export default {
  components: {
    Default,
    Auth
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout
      return layout || Auth
    }
  }
}
</script>
